import React from "react";

const Waitingesec = () => {
  return (
    <div className="subpages-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading"> Waiting a Content</h3>
          </div>

      
        </div>
      </div>
    </div>
  );
};
export default Waitingesec;
