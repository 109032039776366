import React from "react";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import Waitingesec  from "../components/gallery/waitingsec";

const Gallerypage = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Waiting" />
      <Layout isSticky>
           <section>
             <Waitingesec />   
           </section>
      </Layout>
    </div>
  );
};

export default Gallerypage;
